import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

import { useGetCustomerAddresses } from './getCustomerAddresses'

export interface IUpdateAddressResponse {
  messageTitle: string
  messageDescription: string
  messageType: string
  messageCode: string
  data: any
}

export interface IUpdateAddressReq {
  id: string
  streetAddress?: string
  suburb?: string
  city?: string
  postcode?: string
  attentionTo?: string
  isDefaultAddress?: boolean
}

export const updateAddress = async (req: IUpdateAddressReq) => {
  const data = await httpPOST({
    url: `customer/updateaddress`,
    body: {
      ...req,
      isDefault: req.isDefaultAddress
    }
  })

  return data?.data as IUpdateAddressResponse
}

export const useUpdateAddress = () => {
  const { getCustomerAddresses } = useGetCustomerAddresses()
  /* Use Query */
  const { data, mutateAsync, ...restOptions } = useMutation({
    mutationFn: async (req: IUpdateAddressReq) => {
      const res = await updateAddress({ ...req })
      getCustomerAddresses()
      return res
    }
  })

  return { updateAddressResponse: data, updateAddressAsync: mutateAsync, ...restOptions }
}

import React, { FC, useState } from 'react'
import { Button, Lozenge, Modal, ModalBody, ModalFooter } from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface AddressProps {
  id: string
  displayName?: string
  firstName: string
  lastName: string
  contactNumber: string
  businessName: string
  addressDetails: string
  streetAddress: string
  city: string
  suburb: string
  country: string
  postCode: string
  latitude: string | null
  longitude: string | null
  attentionTo: string
  isDefault: boolean
  isInvoiceAddress: boolean
  isAccountShippingAddress: boolean
}

export interface AddressCardProps {
  className?: string
  componentId?: string
  data: AddressProps
  onRemoveClick?: () => void
  onEditClick?: () => void
}

export const AddressCard: FC<AddressCardProps> = ({
  className,
  componentId,
  data,
  onRemoveClick,
  onEditClick,
  ...restProps
}: AddressCardProps) => {
  const AddressCardClasses = CN(
    `${componentId}-address-card flex flex-col w-full border p-5 rounded justify-between`,
    {
      'bg-B-25 border-B-500': data?.isDefault,
      'bg-white border-N-50': !data?.isDefault || data?.isInvoiceAddress
    },
    className
  )

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

  return (
    <div className={AddressCardClasses} {...restProps}>
      <div>
        {data?.displayName && <span className='font-bold text-base'>{data?.displayName}</span>}
        {data?.attentionTo && <p className='text-base'>{data?.attentionTo}</p>}
        {data?.streetAddress && <p className='text-base'>{data?.streetAddress}</p>}
        {data?.suburb && <p className='text-base'>{data?.suburb}</p>}
        {data?.city && <p className='text-base'>{data?.city}</p>}
        {data?.postCode && <p className='text-base'>{data?.postCode}</p>}
      </div>

      {!data?.isInvoiceAddress && (
        <div className='flex justify-between items-center pt-5'>
          <div>
            {data?.isAccountShippingAddress ? (
              <Lozenge
                icon={CN('ri-checkbox-circle-fill', {
                  '!text-O-500': data?.isDefault
                })}
                variant={data?.isDefault ? 'warning-1' : 'disabled'}
                appearance='subtle'
                className='text-sm text-start pointer-events-none'>
                Account Shipping Address
              </Lozenge>
            ) : (
              data?.isDefault && (
                <Lozenge
                  icon='ri-checkbox-circle-fill !text-O-500'
                  variant={'warning-1'}
                  appearance='subtle'
                  className='text-sm text-start pointer-events-none'>
                  Default
                </Lozenge>
              )
            )}
          </div>
          {!data?.isAccountShippingAddress && (
            <div className='flex gap-x-5'>
              <Button
                size='md'
                appearance='link-gray'
                iconBefore='nzsbi-edit !text-[24px]'
                isRingOffset={false}
                onClick={() => {
                  if (onEditClick) {
                    onEditClick()
                  }
                }}>
                Edit
              </Button>

              <Button
                size='md'
                appearance='link-red'
                iconBefore='nzsbi-delete !text-[24px]'
                isRingOffset={false}
                onClick={() => {
                  setIsOpenDeleteModal(true)
                }}>
                Remove
              </Button>
            </div>
          )}
        </div>
      )}

      <Modal
        isOpen={isOpenDeleteModal}
        className='w-full !max-w-[380px]'
        onClickOverlay={() => {
          setIsOpenDeleteModal(false)
        }}>
        <div className='flex flex-row items-center justify-end h-[52px] px-[20px]'>
          <Button
            iconOnly
            iconBefore='nzsbi-close'
            appearance='link-gray'
            size='xs'
            onClick={() => {
              setIsOpenDeleteModal(false)
            }}
          />
        </div>

        <ModalBody
          componentId='shipping-address-delete-modal-body'
          className='!pt-0 flex flex-col justify-center items-center'>
          <i className='shipping-address-delete-warning-icon ri-error-warning-line text-[40px] text-Y-500 mb-[16px]' />
          <div className='w-full text-center'>
            Do you really want to remove this shipping Address?
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='flex gap-[16px]'>
            <Button
              appearance='secondary-gray'
              size='xs'
              onClick={() => {
                setIsOpenDeleteModal(false)
              }}>
              Cancel
            </Button>

            <Button
              appearance='primary-orange'
              size='xs'
              onClick={() => {
                if (onRemoveClick) {
                  onRemoveClick()
                }
                setIsOpenDeleteModal(false)
              }}>
              Remove
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default AddressCard

import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import {
  Button,
  CheckBox,
  debounce,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  TextField
} from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { getAddressDetails, searchAddresses } from 'lib/actions'
import { useModalContext } from 'lib/contexts/modal-context'

export interface SearchAddressModalProps {
  className?: string
}

const LoadingField = ({ labelText }: { labelText: string }) => (
  <div className='flex flex-col'>
    <label className='font-heading font-500 text-base text-N-700'>{labelText}</label>
    <Skeleton height={44} />
  </div>
)

export const SearchAddressModal: FC<SearchAddressModalProps> = ({
  className,
  ...restProps
}: SearchAddressModalProps) => {
  const { searchAddressModal } = useModalContext()
  const {
    attentionTo,
    city,
    close,
    isDefaultAddress,
    isOpen,
    isPrimaryBtnLoading,
    isUpdateAccountDetails,
    postcode,
    primaryBtnText,
    saveFn,
    searchingAddress,
    setAddressDetails,
    setPrimaryBtnText,
    setSaveFn,
    setTitle,
    showAttentionTo,
    showMakeDefaultAddress,
    streetAddress,
    suburb,
    title
  } = searchAddressModal

  const emptyAddress = {
    city: '',
    postcode: '',
    searchingAddress: '',
    streetAddress: '',
    suburb: ''
  }

  const [address, setAddress] = useState({ ...emptyAddress })
  const [additionalFields, setAdditionalFields] = useState({
    attentionTo: '',
    isDefaultAddress: false
  })

  const [addresses, setAddresses] = useState([] as Array<{ id: string; address: string }>)
  const [openSuggestions, setOpenSuggestions] = useState<boolean>(false)
  const [isSearchPending, setIsSearchPending] = useState<boolean>(false)
  const [isAddressDetailsPending, setIsAddressDetailsPending] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isOpen) {
      inputRef?.current?.focus()

      setAddress({
        city,
        postcode,
        searchingAddress,
        streetAddress,
        suburb
      })

      setAdditionalFields({
        ...(showAttentionTo && { attentionTo }),
        ...(showMakeDefaultAddress && { isDefaultAddress })
      })
    }

    return () => setOpenSuggestions(false)
  }, [isOpen])

  const optimizedSearchAddress = useCallback(
    debounce(async (e: ChangeEvent<HTMLInputElement>) => {
      setIsSearchPending(true)
      setOpenSuggestions(true)
      const { addresses: addressesRes } = await searchAddresses(e.target.value)
      if (addressesRes?.length) {
        setAddresses(addressesRes)
      } else {
        setAddresses([])
      }
      setIsSearchPending(false)
    }, 500),
    []
  )

  const handleSearchAddressChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      setAddress({ ...address, searchingAddress: e.target.value })
    } else {
      setAddress({
        ...emptyAddress
      })
    }
    optimizedSearchAddress(e)
  }

  const fetchAddressDetails = async (id: string, completeAddress: string) => {
    setIsAddressDetailsPending(true)
    const { customerAddress: addressDetails } = await getAddressDetails(id)

    setAddress({
      city: addressDetails?.city,
      postcode: addressDetails?.postCode,
      searchingAddress: completeAddress,
      streetAddress: addressDetails?.streetAddress,
      suburb: addressDetails?.suburb
    })
    setIsAddressDetailsPending(false)
  }

  const handleSaveAddress = () => {
    saveFn({
      ...address,
      ...(showAttentionTo && { attentionTo: additionalFields.attentionTo }),
      ...(showMakeDefaultAddress && { isDefaultAddress: additionalFields.isDefaultAddress })
    })

    close()

    setAddressDetails({
      attentionTo: '',
      city: '',
      isDefaultAddress: false,
      postcode: '',
      searchingAddress: '',
      showAttentionTo: false,
      showMakeDefaultAddress: false,
      streetAddress: '',
      suburb: ''
    })
    setSaveFn(() => () => {})
    setPrimaryBtnText(undefined)
    setTitle('')
  }

  return (
    <div className={CN('search-address-modal', className)} {...restProps}>
      <Modal
        componentId='search-address-modal'
        isOpen={isOpen}
        onClickOverlay={close}
        className={CN('w-[444px] flex-shrink-0', {
          'w-[620px]': isUpdateAccountDetails
        })}>
        <ModalHeader
          componentId='search-address-modal'
          onClose={close}
          heading={title}
          hasBorder={false}
        />
        <ModalBody>
          <div className='flex flex-col gap-[20px]'>
            <div className='relative'>
              <TextField
                componentId='address-search-input'
                label='Search Address'
                required={!isUpdateAccountDetails}
                iconAfter='nzsbi-search'
                placeholder={isUpdateAccountDetails && 'Type in your address'}
                value={address.searchingAddress}
                wrapperClassName='w-full pt-1'
                onChange={handleSearchAddressChange}
                onClick={() => {
                  setOpenSuggestions(true)
                }}
              />

              {isSearchPending && address.searchingAddress !== '' && (
                <div className='absolute top-[77px] w-full border-[1px] border-N-100 shadow-lg z-20 bg-white flex justify-center items-center h-[250px]'>
                  <Spinner loop={true} />
                </div>
              )}

              {!!addresses?.length && openSuggestions && (
                <div
                  data-component-id='address-search-dropdown'
                  className='flex flex-col absolute top-[77px] border-[1px] border-N-100 shadow-lg z-20 bg-white w-full px-3 pb-3 overflow-y-auto max-h-[270px]'>
                  {addresses?.map((item: any) => (
                    <div
                      data-component-id='address-search-dropdown-item'
                      key={item?.id}
                      aria-hidden={true}
                      onClick={() => {
                        fetchAddressDetails(item?.id, item?.address)
                        setOpenSuggestions(prev => !prev)
                        setAddress(prev => ({ ...prev, searchingAddress: item.address }))
                      }}
                      className='flex flex-col cursor-pointer py-2 hover:bg-N-25'>
                      <p
                        data-component-id='address-search-dropdown-item-title'
                        className='font-400 text-base text-N-400'>
                        {item?.address}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className='relative flex flex-col gap-[20px]'>
              {isAddressDetailsPending ? (
                <LoadingField labelText='Street Address' />
              ) : (
                <TextField
                  componentId='address-search-street-address'
                  label='Street Address'
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAddress(prev => ({ ...prev, streetAddress: e.target.value }))
                  }
                  value={address.streetAddress}
                />
              )}
              {isAddressDetailsPending ? (
                <LoadingField labelText='Suburb' />
              ) : (
                <TextField
                  componentId='address-search-suburb'
                  label='Suburb'
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAddress(prev => ({ ...prev, suburb: e.target.value }))
                  }
                  value={address.suburb}
                />
              )}
              <div className='grid grid-cols-2 gap-[32px] w-full'>
                {isAddressDetailsPending ? (
                  <LoadingField labelText='City' />
                ) : (
                  <TextField
                    componentId='address-search-city'
                    label='City'
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setAddress(prev => ({ ...prev, city: e.target.value }))
                    }
                    value={address.city}
                  />
                )}
                {isAddressDetailsPending ? (
                  <LoadingField labelText='Postcode' />
                ) : (
                  <TextField
                    componentId='address-search-postcode'
                    label='Postcode'
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setAddress(prev => ({ ...prev, postcode: e.target.value }))
                    }
                    value={address.postcode}
                  />
                )}
              </div>
              {showAttentionTo && (
                <TextField
                  label='Attention to (Optional)'
                  componentId={`${title}-attention-to`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAdditionalFields(prev => ({ ...prev, attentionTo: e.target.value }))
                  }
                  value={additionalFields.attentionTo}
                  maxLength={160}
                />
              )}
              {showMakeDefaultAddress && (
                <CheckBox
                  checked={additionalFields.isDefaultAddress}
                  componentId={`${title}-make-default-address-checkbox`}
                  helpText='Address will be pre-selected at Checkout for your convenience.'
                  id='make-default-address-checkbox'
                  label='Make this my Default Shipping Address'
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAdditionalFields(prev => ({ ...prev, isDefaultAddress: e.target.checked }))
                  }
                />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter componentId='bulk-order-unsaved-warning'>
          <div className='flex gap-[16px]'>
            <Button
              componentId='search-address-cancel-btn'
              appearance='secondary-gray'
              onClick={close}>
              Cancel
            </Button>
            <Button
              isLoading={isPrimaryBtnLoading}
              appearance='primary-orange'
              className='mr-2'
              componentId='search-address-save-btn'
              disabled={isAddressDetailsPending}
              onClick={handleSaveAddress}>
              {primaryBtnText ?? 'Save Address'}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default SearchAddressModal

import React, { FC } from 'react'
import { GoogleCaptcha } from '@nzsb/shopnx-ui'

import { LoginForm } from './LoginForm'

export interface ILoginProps {
  [x: string]: any
  className?: string
  hideHeader?: boolean
}

export const Login: FC<ILoginProps> = ({ ...restProps }: ILoginProps) => {
  return (
    <GoogleCaptcha className='w-full' reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_SITE_KEY}>
      <LoginForm {...restProps} />
    </GoogleCaptcha>
  )
}

Login.defaultProps = {
  className: undefined
}

export default Login

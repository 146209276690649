import { httpGET } from 'lib/http'

export interface ISearchAddressesResponse {
  addresses: Array<{ id: string; address: string }>
}

export const searchAddresses = async (query: string) => {
  const { data } = await httpGET({
    url: `/addresses/search/${query}`
  })
  return data?.data as ISearchAddressesResponse
}

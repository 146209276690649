import React from 'react'
import { Button } from '@nzsb/shopnx-ui'

import { FastSignIn } from '../FastSignIn'

export const CheckoutFaster: React.FC = () => {
  return (
    <div className='flex flex-col gap-5 w-full'>
      <div className='flex flex-col md:flex-row w-full gap-4 md:items-center justify-between bg-white p-5'>
        <h2 className='text-h2 text-N-800 font-bold'>Checkout Faster</h2>
        <div className='flex flex-col gap-4'>
          <Button appearance='secondary-orange'>Sign In</Button>
          <Button appearance='link'>Create an Online Account</Button>
        </div>
      </div>
      <div>
        <FastSignIn />
      </div>

      <div className='flex flex-row gap-4 w-full items-center'>
        <hr className='border-N-100 h-[1px] w-full' />
        Or
        <hr className='border-N-100 h-[1px] w-full' />
      </div>
      <h2 className='text-h2 text-N-800 font-bold'>Continue as Guest</h2>
    </div>
  )
}

export default CheckoutFaster

import React from 'react'
import { GoogleCaptcha, TextField } from '@nzsb/shopnx-ui'

import { PasswordInput } from 'components/atoms'

export const FastSignIn: React.FC = () => {
  return (
    <GoogleCaptcha className='w-full' reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_SITE_KEY}>
      <div className='flex flex-col gap-6 bg-B-25 p-4'>
        <h3 className='text-h3 text-N-800 font-semibold'>Sign In</h3>
        <div className='flex w-full'>
          <div className='login__fields flex flex-col md:flex-row  gap-[20px] w-full'>
            <TextField
              id='username'
              componentId='username'
              wrapperClassName='w-full'
              inputSize='md'
              label='Email Address'
              required
              //   value={values.username}
              //   hint={renderUsernameHint({
              //     hintError: errors.username,
              //     emailAddress: values.username
              //   })}
              //   hasError={
              //     invalidAccountDetails ||
              //     accountActivationPending ||
              //     emailAddressNotFound ||
              //     !!errors.username
              //   }
              //   onChange={e => {
              //     handleOnChange({ e, key: 'username', cb: handleChange })
              //     setEmailAddressNotFound(false)
              //   }}
              //   onBlur={handleBlur('username')}
            />

            <PasswordInput
              id='password'
              componentId='password'
              className='w-full'
              //   hint={errors.password && touched.password ? errors.password : ''}
              //   hasError={invalidAccountDetails || !!(errors.password && touched.password)}
              inputSize='md'
              label='Password'
              placeholder=''
              required
              //   value={values.password}
              //   onChange={(e: any) => {
              //     handleOnChange({ e, key: 'password', cb: handleChange })
              //   }}
              //   onBlur={handleBlur('password')}
            />
          </div>
        </div>
      </div>
    </GoogleCaptcha>
  )
}

export default FastSignIn

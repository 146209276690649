export const convertDate = (dateString: string) => {
  /**
   * @param dateString - [string] date in string format
   * @returns - [string] date in dd/mm/yyyy format
   */

  if (dateString === null || dateString === undefined) {
    return ''
  }

  const d = new Date(dateString)
  const mm = (d.getMonth() + 1).toString().padStart(2, '0')
  const dd = d.getDate().toString().padStart(2, '0')
  const yy = d.getFullYear()

  return `${dd}/${mm}/${yy}`
}

export const formatToDMYConversion = (dateString: string) => {
  const date = new Date(dateString)
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  })
}

export default convertDate
